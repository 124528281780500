.brand-color {
    color: $setup-blue !important;
}

.color-gray {
    color: $setup-gray !important;
}

.color-grey {
    color: $setup-grey !important;
}

.color-white {
    color: $setup-white !important;;
}

.color-light {
    color: $setup-light !important;;
}

.color-black {
    color: $setup-black !important;;
}

.error-color {
    color: $setup-error !important;
}
