.border-blue {
    border: 1px solid $setup-blue !important;
}

.border-black {
    border: 1px solid $setup-black !important;
}

.border-grey {
    border: 1px solid $setup-grey !important;
}
