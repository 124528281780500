.subtitle {
    font-family: SF Pro Text;
    font-style: normal;
    font-weight: 600;
    letter-spacing: .5px;

    &-1 {
        @extend .subtitle;
        font-size: 16px;
        line-height: 24px;
    }

    &-2 {
        @extend .subtitle;
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
    }
}

.caption {
    font-family: SF Pro Text;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.heading-1 {
    font-family: SF Pro Display;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
}

.heading-3 {
    font-family: SF Pro Display;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -.3px;
}

.body-regular {
    font-family: SF Pro Text;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    &-little {
        @extend .body-regular;
        font-size: 14px;
        line-height: 20px;
    }
}
