// Variables
@import "variables";

// Fonts
@import "fonts";

// Utils
@import "utils";

// Helpers
@import "helpers";

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    font-family: SF Pro Text !important;

    h1, h2, h3, h4, h5, h6 {
        font-family: SF Pro Display !important;
    }
}

.form-control.is-invalid {
    background-image: none !important;
}

.disabledLoad {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 9999;
    transition: opacity 0.3s ease-in-out;
}

.cursor-pointer {
    cursor: pointer;
}
