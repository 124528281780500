.font-10 {
    font-size: 10px !important;
}

.font-12 {
    font-size: 12px !important;
}

.font-14 {
    font-size: 14px;
}

.font-16 {
    font-size: 16px !important;
}

.font-20 {
    font-size: 20px !important;
}

.font-32 {
    font-size: 32px;
}
