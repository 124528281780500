.btn-blue {
    background: var(--setup-blue) !important;
    color: #FFF !important;
}

.btn-white {
    background: #FFF !important;
    color: var(--setup-blue) !important;
}

.button-white {
    background: #FFF !important;
    color: #000000 !important;
}

.btn-black {
    background: var(--gradient-black, linear-gradient(180deg, #2F2F2F 0%, #000 100%)) !important;
    color: #FFF !important;
}

.btn-setup-blue {
    background: #EDF4FF !important;
    color: var(--setup-blue) !important;
}

