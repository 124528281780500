.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    & > div {
        width: min(375px, 100%);
    }
}
