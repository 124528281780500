.weight-400 {
    font-weight: 400;
}

.weight-600 {
    font-weight: 600;
}

.weight-700 {
    font-weight: 700;
}
