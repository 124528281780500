.w-100 {
    width: 100px;
}

.w-108 {
    width: 108px;
}

.width-100 {
    width: 100%;
}
