$fontInfoMap: (
    'SFPRODISPLAYBLACKITALIC': (
        family: 'SF Pro Display',
        style: 'italic',
        weight: 800,
        filename: 'SF-Pro-Display/SFPRODISPLAYBLACKITALIC.OTF',
    ),
    'SFPRODISPLAYBOLD': (
        family: 'SF Pro Display',
        style: 'normal',
        weight: 700,
        filename: 'SF-Pro-Display/SFPRODISPLAYBOLD.OTF',
    ),
    'SFPRODISPLAYHEAVYITALIC': (
        family: 'SF Pro Display',
        style: 'italic',
        weight: 900,
        filename: 'SF-Pro-Display/SFPRODISPLAYHEAVYITALIC.OTF',
    ),
    'SFPRODISPLAYLIGHTITALIC': (
        family: 'SF Pro Display',
        style: 'italic',
        weight: 300,
        filename: 'SF-Pro-Display/SFPRODISPLAYLIGHTITALIC.OTF',
    ),
    'SFPRODISPLAYMEDIUM': (
        family: 'SF Pro Display',
        style: 'normal',
        weight: 500,
        filename: 'SF-Pro-Display/SFPRODISPLAYMEDIUM.OTF',
    ),
    'SFPRODISPLAYREGULAR': (
        family: 'SF Pro Display',
        style: 'normal',
        weight: 400,
        filename: 'SF-Pro-Display/SFPRODISPLAYREGULAR.OTF',
    ),
    'SFPRODISPLAYSEMIBOLDITALIC': (
        family: 'SF Pro Display',
        style: 'italic',
        weight: 600,
        filename: 'SF-Pro-Display/SFPRODISPLAYSEMIBOLDITALIC.OTF',
    ),
    'SFPRODISPLAYTHINITALIC': (
        family: 'SF Pro Display',
        style: 'italic',
        weight: 100,
        filename: 'SF-Pro-Display/SFPRODISPLAYTHINITALIC.OTF',
    ),
    'SFPRODISPLAYULTRALIGHTITALIC': (
        family: 'SF Pro Display',
        style: 'italic',
        weight: 200,
        filename: 'SF-Pro-Display/SFPRODISPLAYULTRALIGHTITALIC.OTF',
    ),
    'SF-Pro-Text-Black': (
        family: 'SF Pro Text',
        style: 'normal',
        weight: 900,
        filename: 'SF-Pro-Text/SF-Pro-Text-Black.otf',
    ),
    'SF-Pro-Text-BlackItalic': (
        family: 'SF Pro Text',
        style: 'italic',
        weight: 900,
        filename: 'SF-Pro-Text/SF-Pro-Text-BlackItalic.otf',
    ),
    'SF-Pro-Text-Bold': (
        family: 'SF Pro Text',
        style: 'normal',
        weight: 700,
        filename: 'SF-Pro-Text/SF-Pro-Text-Bold.otf',
    ),
    'SF-Pro-Text-BoldItalic': (
        family: 'SF Pro Text',
        style: 'italic',
        weight: 700,
        filename: 'SF-Pro-Text/SF-Pro-Text-BoldItalic.otf',
    ),
    'SF-Pro-Text-Heavy': (
        family: 'SF Pro Text',
        style: 'normal',
        weight: 800,
        filename: 'SF-Pro-Text/SF-Pro-Text-Heavy.otf',
    ),
    'SF-Pro-Text-HeavyItalic': (
        family: 'SF Pro Text',
        style: 'italic',
        weight: 800,
        filename: 'SF-Pro-Text/SF-Pro-Text-HeavyItalic.otf',
    ),
    'SF-Pro-Text-Light': (
        family: 'SF Pro Text',
        style: 'normal',
        weight: 300,
        filename: 'SF-Pro-Text/SF-Pro-Text-Light.otf',
    ),
    'SF-Pro-Text-LightItalic': (
        family: 'SF Pro Text',
        style: 'italic',
        weight: 300,
        filename: 'SF-Pro-Text/SF-Pro-Text-LightItalic.otf',
    ),
    'SF-Pro-Text-Medium': (
        family: 'SF Pro Text',
        style: 'normal',
        weight: 500,
        filename: 'SF-Pro-Text/SF-Pro-Text-Medium.otf',
    ),
    'SF-Pro-Text-MediumItalic': (
        family: 'SF Pro Text',
        style: 'italic',
        weight: 500,
        filename: 'SF-Pro-Text/SF-Pro-Text-MediumItalic.otf',
    ),
    'SF-Pro-Text-Regular': (
        family: 'SF Pro Text',
        style: 'normal',
        weight: 400,
        filename: 'SF-Pro-Text/SF-Pro-Text-Regular.otf',
    ),
    'SF-Pro-Text-RegularItalic': (
        family: 'SF Pro Text',
        style: 'italic',
        weight: 400,
        filename: 'SF-Pro-Text/SF-Pro-Text-RegularItalic.otf',
    ),
    'SF-Pro-Text-Semibold': (
        family: 'SF Pro Text',
        style: 'normal',
        weight: 600,
        filename: 'SF-Pro-Text/SF-Pro-Text-Semibold.otf',
    ),
    'SF-Pro-Text-SemiboldItalic': (
        family: 'SF Pro Text',
        style: 'italic',
        weight: 600,
        filename: 'SF-Pro-Text/SF-Pro-Text-SemiboldItalic.otf',
    ),
    'SF-Pro-Text-Thin': (
        family: 'SF Pro Text',
        style: 'normal',
        weight: 100,
        filename: 'SF-Pro-Text/SF-Pro-Text-Thin.otf',
    ),
    'SF-Pro-Text-ThinItalic': (
        family: 'SF Pro Text',
        style: 'italic',
        weight: 100,
        filename: 'SF-Pro-Text/SF-Pro-Text-ThinItalic.otf',
    ),
    'SF-Pro-Text-Ultralight': (
        family: 'SF Pro Text',
        style: 'normal',
        weight: 200,
        filename: 'SF-Pro-Text/SF-Pro-Text-Ultralight.otf',
    ),
    'SF-Pro-Text-UltralightItalic': (
        family: 'SF Pro Text',
        style: 'italic',
        weight: 200,
        filename: 'SF-Pro-Text/SF-Pro-Text-UltralightItalic.otf',
    )
);

@each $font, $info in $fontInfoMap {
    $family: map-get($info, family);
    $style: map-get($info, style);
    $weight: map-get($info, weight);
    $filename: map-get($info, filename);

    @font-face {
        font-family: '#{$family}';
        font-style: #{$style};
        font-weight: #{$weight};
        src: url(../assets/fonts/#{$filename}) format('opentype');
    }
}
