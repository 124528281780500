// Colors
$setup-blue: var(--setup-blue);
$setup-gray: var(--setup-gray);
$setup-white: var(--setup-white);
$setup-light: var(--setup-light);
$setup-black: var(--setup-black);
$setup-grey: var(--setup-grey);
$setup-error: var(--setup-error);

:root {
    --setup-blue: #0D4AE7;
    --setup-gray: #646464;
    --setup-white: #FFFFFF;
    --setup-light: #E6E6E6;
    --setup-black: #000000;
    --setup-grey: #C8C8C8;
    --setup-error: #DF2323;
}
